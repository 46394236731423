// import localForage from 'localforage';
const myStorage = window.localStorage;
export const getKey = (k) => {
  try {
    const json = myStorage.getItem(k);
    if (json) {
      return JSON.parse(json);
    } else {
      return null;
    }
  } catch (err) {
    console.error('ERROR getKey ', err);
    return null;
  }
};

export const setKey = (k, v) => {
  try {
    if (!k) {
      throw new Error(`empty key name`);
    }
    if (!v) {
      throw new Error(`empty value for key '${k}'`);
    }
    console.log(`set ${k}`, v);
    myStorage.setItem(k, JSON.stringify(v));
  } catch (err) {
    console.error('ERROR setKey ', err);
  }
};

export const remKey = (k) => {
  try {
    myStorage.removeItem(k);
  } catch (err) {
    console.error('ERROR remKey ', err);
  }
};
