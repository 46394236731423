import React, { useEffect, useState } from 'react';
import { loadScript } from '@paypal/paypal-js';

const PaypalButton = ({ amount = 0.01, handleSuccess }) => {
  const handleCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };

  const handleOnApprove = (data, actions) => {
    console.log('approve data', data);
    return actions.order.capture().then((details) => {
      console.log('capture details', details);
      // alert('Transaction completed by ' + details.payer.name.given_name);
      handleSuccess(details);
    });
  };

  const handleCancel = (data) => {
    console.log('cancel', data);
    alert('Transaction cancelled ');
  };

  const handleError = (error) => {
    console.log('error', error);
    alert('Transaction errored ');
  };

  useEffect(() => {
    loadScript({ 'client-id': 'test', currency: 'EUR' })
      .then((paypal) => {
        paypal
          .Buttons({
            createOrder: handleCreateOrder,
            onApprove: handleOnApprove,
            onCancel: handleCancel,
            onError: handleError,
          })
          .render('#paypal-button-container');
      })
      .catch((err) => {
        console.error('failed to load the PayPal JS SDK script', err);
      });
  }, []);

  return <div id="paypal-button-container" />;
};

export default PaypalButton;
