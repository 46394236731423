import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Image,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  const alignBlocks = useBreakpointValue({ sm: 'center', md: '' });
  const alignLinks = useBreakpointValue({ sm: 'center', md: 'flex-start' });
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'7xl'} py={10} alignItems={alignBlocks}>
        <SimpleGrid
          templateColumns={{ sm: '1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box
              d="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box boxSize={'80px'} mb={4}>
                <Image src="/images/logo.png" />
              </Box>
              <Text fontSize={'sm'}>
                © {new Date().getFullYear()} Copisteria Universale.
              </Text>
            </Box>
          </Stack>
          <Stack align={alignLinks}>
            <ListHeader>Product</ListHeader>
            <Link href={'#'} fontSize={'sm'}>
              Overview
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Features
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Tutorials
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Pricing
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Releases
            </Link>
          </Stack>
          <Stack align={alignLinks}>
            <ListHeader>Company</ListHeader>
            <Link href={'#'} fontSize={'sm'}>
              About
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Press
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Careers
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Contact
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Partners
            </Link>
          </Stack>
          <Stack align={alignLinks}>
            <ListHeader>Support</ListHeader>
            <Link href={'#'} fontSize={'sm'}>
              Help Center
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Terms of Service
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Legal
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Privacy Policy
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Status
            </Link>
          </Stack>
          <Stack align={alignLinks}>
            <ListHeader>Follow Us</ListHeader>
            <Link href={'#'} fontSize={'sm'}>
              Facebook
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Twitter
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Dribbble
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              Instagram
            </Link>
            <Link href={'#'} fontSize={'sm'}>
              LinkedIn
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
