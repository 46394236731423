
import Checklist from 'components/front-only/Checklist';
import Features from 'components/front-only/Features';
import SearchInput from 'components/front-only/SearchInput';
import Layout from 'components/front-only/Layout';
function App() {
  return (
    <Layout hero={true}>
      <Features />
      <SearchInput />
      <Checklist />
    </Layout>
  );
}

export default App;
