import { assign, Machine } from 'xstate';
import _ from 'lodash';

import * as api from './api';

const fetchOrders = async (context, event) => {
  const { limit = 20, page = 1 } = event;
  return await api.getOrders(limit, page);
};

const fetchOrder = async (context, event) => {
  const { orderId } = event;
  return await api.getOrderById(orderId);
};

const doLogin = async (context, event) => {
  const { values } = event;
  const response = await api.signIn(values);
  console.log('response', response);
  return response;
};

const doLogout = async (context, event) => {
  const response = await api.signOut();
  console.log('logout response', response);
  return true;
};

const isAuthError = (context, event) => {
  const { error } = context;
  console.log('ERROR', error);
  return error === '401' || error === 401;
};

function createAppMachine(intialContext = null) {
  return Machine({
    id: 'app',
    initial: 'init',
    context: intialContext
      ? intialContext
      : {
          loading: false,
          error: undefined,
          auth: undefined,
          data: null,
          order: null,
        },
    states: {
      init: {},
      auth: {
        states: {
          login: {
            invoke: {
              id: 'doLogin',
              src: doLogin,
              onDone: {
                target: 'success',
                actions: assign({
                  auth: (context, event) => event.data,
                  loading: false,
                }),
              },
              onError: {
                target: 'fail',
                actions: assign({
                  error: (context, event) => {
                    console.log('EVENT ERROR', event.toString());
                    return 'ERROR';
                  },
                  loading: false,
                }),
              },
            },
          },
          success: {},
          fail: {
            always: [
              {
                EVENT: '^logout',
                cond: isAuthError,
                actions: assign({ error: () => null }),
              },
            ],
          },
        },
      },
      logout: {
        initial: 'notify',
        states: {
          notify: {
            invoke: {
              id: 'doLogout',
              src: doLogout,
              onDone: {
                target: 'success',
                actions: assign({
                  auth: (context, event) => event.data,
                  loading: false,
                }),
              },
              onError: {
                target: 'fail',
                actions: assign({
                  error: (context, event) => event.data,
                  loading: false,
                }),
              },
            },
          },
          success: {},
          fail: {},
        },
      },

      list: {
        states: {
          loading: {
            invoke: {
              id: 'fetchOrders',
              src: fetchOrders,
              onDone: {
                target: 'success',
                actions: assign({
                  data: (context, event) => event.data,
                  loading: false,
                }),
              },
              onError: {
                target: 'fail',
                actions: assign({
                  error: (context, event) => event.data,
                  loading: false,
                }),
              },
            },
          },
          success: {},
          fail: {},
        },
      },

      get_order: {
        initial: 'loading',
        states: {
          loading: {
            invoke: {
              id: 'fetch_order',
              src: fetchOrder,
              onDone: {
                target: 'success',
                actions: assign({
                  order: (context, event) => event.data,
                  loading: false,
                }),
              },
              onError: {
                target: 'fail',
                actions: assign({
                  error: (context, event) => event.data,
                  loading: false,
                }),
              },
            },
          },
          success: {},
          fail: {},
        },
      },

      detail: {
        states: {
          selected: {},
        },
      },
    },
    on: {
      LOGIN: {
        target: 'auth.login',
        actions: assign({ loading: () => true, error: () => null }),
      },
      LOGOUT: {
        target: 'logout',
        actions: assign({ loading: () => true, error: () => null }),
      },
      FETCH: {
        target: 'list.loading',
        actions: assign({ loading: () => true, error: () => null }),
      },
      GET_ORDER: {
        target: 'get_order',
        actions: assign({
          loading: () => true,
          error: () => null,
          orderId: (context, event) => event.orderId,
        }),
      },
    },
  });
}

// export const MachineContext = createContext();
export default createAppMachine;
