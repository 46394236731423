import { Box, VStack, Heading, StackDivider } from '@chakra-ui/react';

import Empty from './Empty';
import OrderSummary from './OrderSummary';

function ListOrders({ orders }) {
  console.log('DATA', orders);
  if (!orders) return <Empty collection="Orders" />;
  return (
    <Box>
      <VStack
        maxH="80vh"
        overflowY="scroll"
        w="full"
        // divider={<StackDivider />}
        spacing={4}
      >
        {orders.map((item) => {
          return <OrderSummary {...item} />;
        })}
      </VStack>
    </Box>
  );
}

export default ListOrders;
