import { createContext, useState, useEffect } from 'react';
import { useMachine } from '@xstate/react';

import createAppMachine from 'lib/state';
// import useLocalStorage from 'lib/useLocalStorage';
import { getKey } from './useLocalStorage.js';

const StateContext = createContext();
function StateProvider({ children }) {
  // const [persistedState, setPersistedState] = useLocalStorage('cudash', null);
  // const [state, send, service] = useMachine(createAppMachine(persistedState));
  // useEffect(() => {
  //   const subscription = service.subscribe((state) => {
  //     // console.log('STATE++', state);
  //     setPersistedState(state.context);
  //   });
  //   return subscription.unsubscribe;
  // }, [service]);

  const auth = getKey('auth');
  const [state, send, service] = useMachine(
    createAppMachine({
      loading: false,
      error: undefined,
      auth,
      data: null,
      order: null,
    })
  );

  return (
    <StateContext.Provider value={[state, send]}>
      {children}
    </StateContext.Provider>
  );
}

export { StateContext, StateProvider };
