import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme';
import { StateProvider } from 'lib/ctx';
import Home from './pages/Home';
import Dash from './pages/Dash';
import Order from './pages/Order';
import Error from './pages/Error';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <StateProvider>
      <Router>
        <Home path="/" />
        <Dash path="dashboard" />
        <Order path="order/:orderId" />
        <Error default />
      </Router>
    </StateProvider>
  </ChakraProvider>,
  document.getElementById('root')
);
