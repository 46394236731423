import { Box, Text, Flex, Spacer, Divider } from '@chakra-ui/react';

import DetailDialog from './DetailDialog';

import { formatPrice, formatDate } from 'lib/utils';

function OrderSummary({ payment, order }) {
  const type = 'Ordine';
  const { id, data, code } = order;
  const { info, place } = data;
  return (
    <Flex
      key={id}
      w="full"
      borderWidth={2}
      borderRadius="md"
      boxShadow="sm"
      p={5}
      alignItems="center"
      justifyContent="flex-start"
      bg="white"
      _hover={{
        background: 'gray.800',
        color: 'yellow.300',
      }}
    >
      <Box>
        <Text fontSize="sm">{`${type} n° ${id}`}</Text>
        <Text fontSize="xs">{`${code}`}</Text>
        <Text as="span" fontSize="sm">
          {place?.nick}
        </Text>
        <Text as="span" fontSize="md" fontWeight="bold" ml={2}>{`${formatPrice(
          data.total
        )}`}</Text>
        <Text fontSize="xs" color="gray.400">
          {formatDate(data.updatedAt)}
        </Text>
      </Box>
      {payment && (
        <Box borderLeftWidth={2} ml={6} px={2}>
          <Text fontSize="sm">{`Payment ${payment.id}`}</Text>
          <Text
            fontSize="sm"
            color={payment.isPaid ? 'green.300' : 'yellow.400'}
          >
            STATUS: {payment.isPaid ? 'paid' : 'pending'}
          </Text>
          <Text fontSize="xs" color={'gray.400'}>
            STATE: {payment.status}
          </Text>
        </Box>
      )}
      {info && (
        <Box borderLeftWidth={2} ml={6} px={2}>
          <Text fontSize="sm">
            {info?.title}
            {info?.isCompany ?? ' (company)'}
          </Text>
          <Text fontSize="xs" color="gray.400">
            {info?.email}
          </Text>
        </Box>
      )}

      <Spacer />
      <Box>
        <DetailDialog order={order} />
      </Box>
    </Flex>
  );
}

export default OrderSummary;
