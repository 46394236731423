import {
  Flex,
  Container,
  Heading,
  Stack,
  HStack,
  Text,
  Button,
  Image,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';

export default function SearchInput() {
  const navigate = useNavigate();
  const size = 21;
  const schema = yup.object().shape({
    code: yup
      .string()
      .min(size, `il codice deve essere di 21 caratteri`)
      .max(size, `il codice dessere di 21 caratteri`)
      .required('Rquired'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    console.log('form data', values);
    const { code } = values;
    console.log(code);
    navigate(`/order/${code}`);
  };

  return (
    <Container maxW={'7xl'} borderColor="gray.100" borderWidth={1} my={10}>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Stack
          w={{ base: 'full', md: '70%' }}
          spacing={{ base: 2, md: 4 }}
          py={{ base: 10, md: 15 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '3xl', md: '5xl' }}
          >
            Ricerca un ordine.
          </Heading>
          <Text color={'gray.500'} maxW={'lg'}>
            Inserisci il codice che trovi nella mail.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors?.code} mt={4}>
              <Stack spacing={10} direction={'row'} pb={{ base: 20, md: 35 }}>
                <Input
                  size={'xl'}
                  rounded={'md'}
                  px={6}
                  colorScheme={'orange'}
                  name="code"
                  placeholder="Ricerca ..."
                  {...register('code')}
                />
                <Button rounded={'full'} px={6} type="submit">
                  Cerca
                </Button>
              </Stack>
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
            </FormControl>
          </form>
        </Stack>
        <Flex w={{ base: 'full', md: '30%' }} align="center" justify="center">
          <Image
            src="/images/svg/search.svg"
            height={{ sm: '8rem', lg: '12rem' }}
            maxW={'100%'}
          />
        </Flex>
      </Stack>
    </Container>
  );
}
