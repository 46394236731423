import {
  Box,
  Text,
  Table,
  Vstack,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { getNumber, formatPrice, formatDate } from 'lib/utils';

function OrderDetail({ order, showProcessingNotes = true }) {
  const { id, data, code } = order;
  const { info, place, summary } = data;

  function renderRow(item) {
    const { title, quantity, amount } = item;

    const euroAmount = getNumber(amount / 100);
    const formattedAmount = formatPrice(euroAmount, 2);

    const totalNotax = (euroAmount / 122) * 100;
    const totalNotaxFormatted = formatPrice(totalNotax, 3);

    const tax = euroAmount - totalNotax;
    const taxFormatted = formatPrice(tax, 3);
    const formattedPrice = formatPrice(amount / quantity / 122, 3);
    return (
      <Tr key={item.id}>
        <Td fontSize="xs">{title}</Td>
        <Td fontSize="xs">{quantity}</Td>
        <Td fontSize="xs">{formattedPrice}</Td>
        <Td fontSize="xs">{taxFormatted}</Td>
        <Td fontSize="xs">{totalNotaxFormatted}</Td>
        <Td fontSize="xs">{formattedAmount}</Td>
      </Tr>
    );
  }

  return (
    <Box>
      <Flex mb={3} justifyContent="space-between">
        <Box py={4}>
          <Box>
            <Text
              as="span"
              fontSize="md"
              fontWeight="bold"
            >{`Total: ${formatPrice(data.total)}`}</Text>
          </Box>
          <Box>
            <Text fontSize="xs">{`CODE  -  ${code}`}</Text>
          </Box>
          <Box>
            <Text fontSize="xs">{`ID  -  ${id}`}</Text>
          </Box>
          <Box>
            <Text as="span" fontSize="sm">
              {place?.nick},
            </Text>
            {'   '}
            <Text as="span" fontSize="xs" color="gray.400">
              {formatDate(data.updatedAt)}
            </Text>
          </Box>
        </Box>
        {info && (
          <Box>
            <Text fontSize="sm">
              {`${info?.title ? info.title : ''} ${
                info?.title && info?.isCompany ? '(company)' : ''
              }`}
            </Text>
            <Text fontSize="xs" color="gray.400">
              {info?.email}
            </Text>
          </Box>
        )}
      </Flex>
      {info?.note && (
        <>
          <Heading fontSize="sm" my={3}>
            Note per il cliente
          </Heading>
          <Box borderLeftWidth={2} p={5} bg="gray.100">
            <Text fontSize="sm">{info.note}</Text>
          </Box>
        </>
      )}
      {showProcessingNotes && info?.processing_notes && (
        <>
          <Heading fontSize="sm" my={3}>
            Note di Lavorazione
          </Heading>
          <Box borderLeftWidth={2} p={5} bg="gray.100">
            <Text fontSize="sm">{info.processing_notes}</Text>
          </Box>
        </>
      )}

      <Table size="xs" my={10}>
        <Thead>
          <Tr>
            <Th fontSize="xs">Title</Th>
            <Th fontSize="xs">QT</Th>
            <Th fontSize="xs">PU</Th>
            <Th fontSize="xs">iva</Th>
            <Th fontSize="xs">tot</Th>
            <Th fontSize="xs">Tot + iva</Th>
          </Tr>
        </Thead>
        <Tbody>{summary.map((item) => renderRow(item))}</Tbody>
        <Tfoot>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th fontSize="xs">{formatPrice(data.total)}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );
}

export default OrderDetail;
