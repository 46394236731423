import { useState } from 'react';

const windowExists = typeof window !== 'undefined';

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = windowExists ? window.localStorage.getItem(key) : null;
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      windowExists &&
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const getKey = (key) => {
  try {
    const item = window.localStorage.getItem(key);
    console.log('====================================');
    console.log(item);
    console.log('====================================');
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const setKey = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const remKey = (key) => {
  return window.localStorage.removeItem(key);
};

export default useLocalStorage;
