import { VStack, Heading } from '@chakra-ui/react';

function Empty({ collection }) {
  return (
    <VStack
      rounded="sm"
      boxShadow="sm"
      p={5}
      d="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Heading fontSize="xl">{`Loading ${collection} ...`}</Heading>
      <img src="images/svg/empty.svg" />
    </VStack>
  );
}

export default Empty;
