import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from '@reach/router';
import {
  Box,
  Spinner,
  Button,
  Flex,
  Icon,
  Text,
  Alert,
  AlertTitle,
  AlertIcon,
  Center,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { Link } from '@reach/router';

import { useOrderApi } from 'lib/apiHooks';
import { updatePayment } from 'lib/api';
import OrderDetail from 'components/shared/OrderDetail';
import PaypalButton from 'components/front-only/PaypalButton';
import Layout from 'components/front-only/Layout';

function Order() {
  const params = useParams();
  const navigate = useNavigate();

  if (!params.orderId) {
    navigate('/', { replace: true });
  }

  const [processing, setProcessing] = useState(false);
  const { orderId } = params;
  const { data, error, loading, mutate } = useOrderApi(orderId);

  const getAmount = (data) => {
    let amount = null;
    try {
      const strAmount = data?.order?.data.total;
      if (strAmount) {
        amount = parseFloat(strAmount);
      }
    } catch (error) {
      console.error(error);
    }
    return amount;
  };

  const handlePayment = async (data, id, gateway = '') => {
    setProcessing(true);
    const payload = {
      data,
      isPaid: true,
      status: 'SUCCESS',
      gateway,
    };
    try {
      await updatePayment(id, payload);
      mutate();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };
  const amount = getAmount(data);
  const paymentId = data?.payment?.id;
  const gateway = data?.payment?.gateway || '';

  return (
    <Layout>
      <Heading py={6} fontSize={'3xl'}>
        Riepilogo Ordine.
      </Heading>
      <Box
        px="6"
        py="4"
        flex="1"
        flexDirection="column"
        d="flex"
        w="full"
        borderWidth="1px"
      >
        {(loading || processing) && (
          <Center>
            <Spinner />
          </Center>
        )}
        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mx={2}>{error}!</AlertTitle>
          </Alert>
        )}
        <Heading as="h3" fontSize="xl">
          ORDINE N° {data?.order?.id}
        </Heading>
        {data?.order && (
          <OrderDetail order={data.order} showProcessingNotes={false} />
        )}
        {data?.payment?.isPaid && (
          <Box>
            <Text size="md" color="gray.700" bg="gray.100">
              {`STATO ORDINE:`}
            </Text>
            <Text fontSize="xl" color="green.300" py={5}>
              <Icon as={FaCheck} mr={2} />
              {`Ordine pagato ${gateway ? ', tramite ' + gateway : ''}.`}
            </Text>
            <Text size="md" color="gray.700">
              {`Status:  ${
                data?.payment?.status === 'SUCCESS'
                  ? 'In Lavorazione'
                  : 'In Consegna / Disponibile per il ritiro'
              }.`}
            </Text>
            <Link to="/">
              <Button mt={5} colorScheme="yellow">
                Back
              </Button>
            </Link>
          </Box>
        )}
        {!data?.payment?.isPaid && amount && (
          <Box maxW={300}>
            <PaypalButton
              amount={amount}
              handleSuccess={(data) => handlePayment(data, paymentId, 'PayPal')}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default Order;
