import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import OrderDetail from 'components/shared/OrderDetail';
function DetailDialog({ order }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const handleAction = () => {
  //   console.log('ID', id);
  //   if (handleConfirm) {
  //     handleConfirm(id);
  //   }
  //   onClose();
  // };
  return (
    <>
      <Button fontSize="sm" size="sm" colorScheme="blackAlpha" onClick={onOpen}>
        <ViewIcon />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrderDetail order={order} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DetailDialog;
