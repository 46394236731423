import axios from 'axios';
import { getKey, setKey, remKey } from './storage';

const endpoint = process.env.REACT_APP_SRV_ENDPOINT;
// console.log('ENDPOINT', endpoint);

const cli = axios.create({
  baseURL: endpoint,
  headers: {
    'Content-Type': 'application/json',
  },
});

cli.interceptors.request.use((config) => {
  const token = getKey('token');
  console.log('TOKEN', token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

cli.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log('err', err);
    if (err?.response?.status === 401) {
      signOut();
    }
    const errorMessage = err?.response?.data?.message || 'generic error';
    console.log('====================================');
    console.log('errorMessage', errorMessage);
    console.log('====================================');
    throw errorMessage;
  }
);

const handleError = (error) => {
  console.log('error', error);
  console.log(Object.keys(error), error.message);
  console.log('status', error?.response?.status);
  throw error.message;
};

export const signIn = async (data) => {
  try {
    const response = await cli.post('/login', data);
    const auth = response?.data?.auth;
    if (auth) {
      console.log('AUTH', auth);
      await setKey('auth', auth);
      const token = auth.hash;
      await setKey('token', token);
    }
    return auth;
  } catch (error) {
    handleError(error);
  }
};

export const signOut = () => {
  // const token = null;
  remKey('auth');
  remKey('token');
};

export const getOrderById = async (id) => {
  if (!id) return null;
  try {
    const response = await cli.get(`/summaries/${id}`);
    return response?.data;
  } catch (error) {
    console.log('====================================');
    console.log('ERROR CATCH', error);
    console.log('====================================');
    // handleError(error);
    throw error;
  }
};

export const getPaymentByCode = async (code) => {
  if (!code) return null;
  try {
    const response = await cli.get(`/payments/find/${code}`);
    return response?.data;
  } catch (error) {
    console.log('====================================');
    console.log('ERROR CATCH', error);
    console.log('====================================');
    // handleError(error);
    throw error;
  }
};

export const updatePayment = async (id, payload) => {
  if (!id) return null;
  try {
    const response = await cli.put(`/payments/${id}`, payload);
    return response?.data;
  } catch (error) {
    console.log('====================================');
    console.log('ERROR CATCH', error);
    console.log('====================================');
    // handleError(error);
    throw error;
  }
};

export const getOrders = async (page = 1, limit = 10) => {
  try {
    const response = await cli.get(
      `/summaries/filter/summary?page=${page}&limit=${limit}`
    );
    return response?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPayments = async (page = 1, limit = 10) => {
  try {
    const response = await cli.get(`/payments?page=${page}&limit=${limit}`);
    return response?.data;
  } catch (error) {
    handleError(error);
  }
};
