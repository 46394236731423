import useSWR from 'swr';

import * as api from 'lib/api';

export function useOrderApi(code) {
  const { data, error, mutate, isValidating } = useSWR(
    `/payments/find/${code}`,
    () => api.getPaymentByCode(code)
  );
  console.log('data', data);
  return {
    loading: isValidating || (!error && !data),
    data,
    error,
    mutate,
  };
}

export function usePaymentApi(page, limit) {
  const {
    data,
    error,
    mutate,
    isValidating,
  } = useSWR(`/payments?page=${page}&limit=${limit}`, () =>
    api.getPayments(page, limit)
  );
  console.log('data', data);
  
  return {
    loading: isValidating || (!error && !data),
    data,
    error,
    mutate,
  };
}
