import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Image,
  Container,
  Heading,
  Center,
} from '@chakra-ui/react';
import { FcAssistant, FcApproval, FcInTransit } from 'react-icons/fc';

const Feature = ({ title, text, icon }) => {
  return (
    <Stack align="center">
      <Flex
        w={40}
        h={40}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}
      >
        <Image src={`images/svg/${icon}.svg`} />
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={4}>
      <Center>
        <Stack spacing={4} maxW={'3xl'} textAlign={'center'} mb={10}>
          <Heading fontSize={'3xl'}>Tutto in tre semplici passi.</Heading>
          <Text color={'gray.600'} fontSize={'xl'}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Text>
        </Stack>
      </Center>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          aicon={<Icon as={FcAssistant} w={10} h={10} />}
          icon="commission"
          title={'Commissione il tuo lavoro'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
        <Feature
          aicon={<Icon as={FcApproval} w={10} h={10} />}
          icon="pay"
          title={'Paga il preventivo online'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
        <Feature
          aicon={<Icon as={FcInTransit} w={10} h={10} />}
          icon="receive"
          title={'Ricevi il lavoro pronto dal corriere'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
