import { Flex, Container } from '@chakra-ui/react';

import Footer from 'components/front-only/Footer';
import Nav from 'components/front-only/Nav';
import Hero from 'components/front-only/Hero';
function Layout({ children, hero = false }) {
  return (
    <Flex
      flexDirection="column"
      w="full"
      minH={'100vh'}
      justifyContent="space-between"
    >
      <Nav />
      <Flex
        flex={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {hero ? <Hero /> : null}
        <Container p={4} maxW={'6xl'}>
          {children}
        </Container>
      </Flex>
      <Footer />
    </Flex>
  );
}

export default Layout;
