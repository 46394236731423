import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Spinner,
  Button,
  Flex,
  Text,
  Alert,
  AlertTitle,
  AlertIcon,
  Center,
  Heading,
  useToast,
} from '@chakra-ui/react';
// import { StateContext } from 'lib/ctx';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RepeatIcon,
} from '@chakra-ui/icons';

import ListOrders from './ListOrders';
import * as api from 'lib/api';
import { usePaymentApi } from 'lib/apiHooks';

const Dashboard = () => {
  // const [machine, send] = useContext(StateContext);
  // const { data, error, loading } = machine.context;

  const [page, setPage] = useState(1);
  const limit = 10;
  const { data, error, loading, mutate } = usePaymentApi(page, limit);

  // const sendToast = () => {
  //   toast({
  //     title: 'Load more.',
  //     description: 'fetching data....',
  //     status: 'success',
  //     duration: 5000,
  //     isClosable: true,
  //     position: 'top-right',
  //   });
  // };

  let lastPage = 1;
  if (data?.pagination) {
    console.log('pagination', data.pagination);
    const { count } = data.pagination;
    lastPage = Math.ceil(count / limit);
  }

  const refresh = () => {
    mutate();
  };

  return (
    <Box
      px="6"
      py="4"
      flex="1"
      flexDirection="column"
      d="flex"
      w="full"
      borderWidth="1px"
    >
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mx={2}>{error}!</AlertTitle>
        </Alert>
      )}

      <Flex mb={5} justifyContent="space-between">
        <Heading as="h4" size="md">
          ORDINI
        </Heading>
        <Box>{loading && <Spinner />}</Box>
        <Button
          size="sm"
          colorScheme="alphaBlack"
          bg="gray.900"
          color="white"
          onClick={() => refresh()}
        >
          <RepeatIcon />
          {` Reload`}
        </Button>
      </Flex>
      <ListOrders orders={data?.orders} />

      <Flex mt={10} w="full" alignItems="center" justifyContent="center" px={4}>
        <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
          <ChevronLeftIcon />
        </Button>
        <Text px={4}>PAGE {page}</Text>
        <Button disabled={page >= lastPage} onClick={() => setPage(page + 1)}>
          <ChevronRightIcon />
        </Button>
      </Flex>
    </Box>
  );
};

export default Dashboard;
