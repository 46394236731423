import { useContext } from 'react';
import { StateContext } from 'lib/ctx';
import { Box, Text, Code, Heading } from '@chakra-ui/react';

import Login from 'components/back-only/Login';
import Dashboard from 'components/back-only/Dashboard';

function Dash() {
  const [machine] = useContext(StateContext);
  const { auth } = machine.context;
  return (
    <Box
      d="flex"
      w="full"
      minH="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bg={'gray.100'}
    >
      {auth ? (
        <Dashboard />
      ) : (
        <>
          <Heading size="xl" mb={5}>
            COPISTERIA UNIVERSALE
          </Heading>
          <Box
            overflowY="auto"
            borderWidth="1px"
            borderRadius="lg"
            alpha={0.7}
            py={4}
            px={6}
            boxShadow="base"
            bg="white"
            minW="320px"
            width={[
              '100%', // 0-30em
              '450px', // 30em-48em
            ]}
          >
            <Login />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Dash;
