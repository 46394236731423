import { extendTheme } from '@chakra-ui/react';

const t = {
  styles: {
    global: (props) => ({
      'html, body': {
        fontSize: 'lg',
        // background: props.colorMode === 'dark' ? '#36353a' : '#f4f4f4',
        // color: props.colorMode === 'dark' ? 'white' : 'gray.600',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Helvetica, sans-serif',
      },
      a: {
        color: props.colorMode === 'dark' ? 'yellow.400' : 'yellow.500',
        textDecoration: 'none',
      },
    }),
  },
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme(t);

export default theme;
