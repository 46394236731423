import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Image,
} from '@chakra-ui/react';

export default function CallToActionWithIllustration() {
  return (
    <Container maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 6 }}
        py={{ base: 20, md: 20 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
        >
          Paga i tuoi ordini online{' '}
          <Text as={'span'} color={'orange.300'}>
            In un Click
          </Text>
        </Heading>
        <Text color={'gray.500'} maxW={'3xl'}>
          Paga i tuoi ordini online, Scegli come ritirare i tuoi lavori, Oppure
          fatteli spedire direttamente a casa. Risparmia tempo e denaro.
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'orange'}
            bg={'orange.300'}
            _hover={{ bg: 'gray.900', color: 'white' }}
          >
            Inizia
          </Button>
          <Button rounded={'full'} px={6}>
            Scopri di più
          </Button>
        </Stack>
        <Flex w={'full'} align="center" justify="center">
          <Image
            src="/images/svg/confirmed.svg"
            height={{ sm: '16rem', lg: '20rem' }}
            mt={{ base: 2, sm: 4 }}
            maxW={'100%'}
          />
        </Flex>
      </Stack>
    </Container>
  );
}
