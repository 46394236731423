import dayjs from 'dayjs';

export const getNumber = (n) => {
  return n ? parseFloat(n) : 0;
};
export const formatPrice = (value, minimumFractionDigits = 2) => {
  const number = getNumber(value);
  const formattedNumber = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits,
  }).format(number);
  return `${formattedNumber.replace('€', '').trim()}€`;
};

export const formatDate = (d) => {
  return dayjs(d).format('DD MMM YYYY HH:mm:ss');
};
